@media (min-width: 1160px) {
  #timeline .tl-card:nth-child(odd) .head::after, #timeline .tl-card:nth-child(even) .head::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }

  #timeline .tl-card:nth-child(odd) .head::before, #timeline .tl-card:nth-child(even) .head::before {
    position: absolute;
    content: "";
    width: 9px;
    height: 9px;
    background-color: #bdbdbd;
    border-radius: 9px;
    box-shadow: 0px 0px 2px 8px #f7f7f7;
  }
}
/* Some Cool Stuff */
.tl-card:nth-child(1) {
  order: 1;
}

.tl-card:nth-child(2) {
  order: 3;
}

.tl-card:nth-child(3) {
  order: 2;
}

#timeline a {
  color: black;
  text-decoration: none;
}

#timeline a:hover {
  color: rgb(133, 133, 133);
  text-decoration: none;
}

#timeline h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 200;
  margin-bottom: 20px;
}
#timeline p.leader {
  text-align: center;
  max-width: 90%;
  margin: auto;
  margin-bottom: 45px;
}
#timeline .tl-card-wrapper {
  position: relative;
  margin: auto;
}
@media (min-width: 1160px) {
  #timeline .tl-card-wrapper {
    display: flex;
    flex-flow: column wrap;
    width: 1170px;
    height: 1100px;
    margin: 0 auto;
  }
}
#timeline .tl-card-wrapper::after {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 1px solid rgba(191, 191, 191, 0.4);
}
@media (min-width: 1160px) {
  #timeline .tl-card-wrapper::after {
    border-left: 1px solid #bdbdbd;
  }
}
#timeline .tl-card {
  position: relative;
  display: block;
  margin: 10px auto 80px;
  max-width: 94%;
  z-index: 2;
}
@media (min-width: 480px) {
  #timeline .tl-card {
    max-width: 90%;
    box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);
  }
}
@media (min-width: 720px) {
  #timeline .tl-card {
    max-width: 70%;
  }
}
@media (min-width: 1160px) {
  #timeline .tl-card {
    max-width: 450px;
    height: 400px;
    margin: 90px;
    margin-top: 45px;
  }
  #timeline .tl-card:nth-child(odd) {
    margin-right: 45px;
  }
  #timeline .tl-card:nth-child(odd) .head::after {
    border-left-width: 15px;
    border-left-style: solid;
    left: 100%;
  }
  #timeline .tl-card:nth-child(odd) .head::before {
    left: 491.5px;
  }
  #timeline .tl-card:nth-child(even) {
    margin-left: 45px;
  }
  #timeline .tl-card:nth-child(even) .head::after {
    border-right-width: 15px;
    border-right-style: solid;
    right: 100%;
  }
  #timeline .tl-card:nth-child(even) .head::before {
    right: 489.5px;
  }
  #timeline .tl-card:nth-child(2) {
    margin-top: 180px;
  }
}
#timeline .tl-card .head {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 400;
}
#timeline .tl-card .head .number-box {
  display: inline;
  float: left;
  margin: 15px;
  padding: 10px;
  font-size: 35px;
  line-height: 35px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.17);
}
#timeline .tl-card .head h2 {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: inherit;
  letter-spacing: 2px;
  margin: 0;
  padding-bottom: 6px;
  color: white;
  margin-top: 10px;
}
@media (min-width: 480px) {
  #timeline .tl-card .head h2 {
    font-size: 165%;
    line-height: 3rem;
  }
}
#timeline .tl-card .head h2 span {
  display: block;
  font-size: 0.6rem;
  margin: 0;
}
@media (min-width: 480px) {
  #timeline .tl-card .head h2 span {
    font-size: 0.8rem;
  }
}
#timeline .tl-card .body {
  background: #fff;
  border: 1px solid rgba(191, 191, 191, 0.4);
  border-top: 0;
  padding: 15px;
}
@media (min-width: 1160px) {
  #timeline .tl-card .body {
    height: 315px;
  }
}
#timeline .tl-card .body p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
}
#timeline .tl-card .body img {
  display: block;
  width: 100%;
}
#timeline .tl-card--step1 {
  background-color: #46b8e9;
}
#timeline .tl-card--step1 .head::after {
  border-color: #46b8e9;
}
#timeline .tl-card--step2 {
  background-color: red;
}
#timeline .tl-card--step2 .head::after {
  border-color: red;
}
#timeline .tl-card--step3 {
  background-color: rgba(30,171,149,1);
}
#timeline .tl-card--step3 .head::after {
  border-color: rgba(30,171,149,1);
}
#timeline .tl-card--step4 {
  background-color: #4d92eb;
}
#timeline .tl-card--step4 .head::after {
  border-color: #4d92eb;
}
#timeline .tl-card--step5 {
  background-color: #46b8e9;
}
#timeline .tl-card--step5 .head::after {
  border-color: #46b8e9;
}
