@media (width >= 1160px) {
  #timeline .tl-card:nth-child(odd) .head:after, #timeline .tl-card:nth-child(2n) .head:after {
    content: "";
    border-top: 15px solid #0000;
    border-bottom: 15px solid #0000;
    width: 0;
    height: 0;
    position: absolute;
  }

  #timeline .tl-card:nth-child(odd) .head:before, #timeline .tl-card:nth-child(2n) .head:before {
    content: "";
    background-color: #bdbdbd;
    border-radius: 9px;
    width: 9px;
    height: 9px;
    position: absolute;
    box-shadow: 0 0 2px 8px #f7f7f7;
  }
}

.tl-card:first-child {
  order: 1;
}

.tl-card:nth-child(2) {
  order: 3;
}

.tl-card:nth-child(3) {
  order: 2;
}

#timeline a {
  color: #000;
  text-decoration: none;
}

#timeline a:hover {
  color: #858585;
  text-decoration: none;
}

#timeline h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 3rem;
  font-weight: 200;
}

#timeline p.leader {
  text-align: center;
  max-width: 90%;
  margin: auto auto 45px;
}

#timeline .tl-card-wrapper {
  margin: auto;
  position: relative;
}

@media (width >= 1160px) {
  #timeline .tl-card-wrapper {
    flex-flow: column wrap;
    width: 1170px;
    height: 1100px;
    margin: 0 auto;
    display: flex;
  }
}

#timeline .tl-card-wrapper:after {
  z-index: 1;
  content: "";
  border-left: 1px solid #bfbfbf66;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}

@media (width >= 1160px) {
  #timeline .tl-card-wrapper:after {
    border-left: 1px solid #bdbdbd;
  }
}

#timeline .tl-card {
  z-index: 2;
  max-width: 94%;
  margin: 10px auto 80px;
  display: block;
  position: relative;
}

@media (width >= 480px) {
  #timeline .tl-card {
    max-width: 90%;
    box-shadow: 0 1px 22px 4px #00000012;
  }
}

@media (width >= 720px) {
  #timeline .tl-card {
    max-width: 70%;
  }
}

@media (width >= 1160px) {
  #timeline .tl-card {
    max-width: 450px;
    height: 400px;
    margin: 45px 90px 90px;
  }

  #timeline .tl-card:nth-child(odd) {
    margin-right: 45px;
  }

  #timeline .tl-card:nth-child(odd) .head:after {
    border-left-style: solid;
    border-left-width: 15px;
    left: 100%;
  }

  #timeline .tl-card:nth-child(odd) .head:before {
    left: 491.5px;
  }

  #timeline .tl-card:nth-child(2n) {
    margin-left: 45px;
  }

  #timeline .tl-card:nth-child(2n) .head:after {
    border-right-style: solid;
    border-right-width: 15px;
    right: 100%;
  }

  #timeline .tl-card:nth-child(2n) .head:before {
    right: 489.5px;
  }

  #timeline .tl-card:nth-child(2) {
    margin-top: 180px;
  }
}

#timeline .tl-card .head {
  color: #fff;
  align-items: center;
  font-weight: 400;
  display: flex;
  position: relative;
}

#timeline .tl-card .head .number-box {
  float: left;
  background: #0000002b;
  margin: 15px;
  padding: 10px;
  font-size: 35px;
  font-weight: 600;
  line-height: 35px;
  display: inline;
}

#timeline .tl-card .head h2 {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: inherit;
  letter-spacing: 2px;
  color: #fff;
  margin: 10px 0 0;
  padding-bottom: 6px;
}

@media (width >= 480px) {
  #timeline .tl-card .head h2 {
    font-size: 165%;
    line-height: 3rem;
  }
}

#timeline .tl-card .head h2 span {
  margin: 0;
  font-size: .6rem;
  display: block;
}

@media (width >= 480px) {
  #timeline .tl-card .head h2 span {
    font-size: .8rem;
  }
}

#timeline .tl-card .body {
  background: #fff;
  border: 1px solid #bfbfbf66;
  border-top: 0;
  padding: 15px;
}

@media (width >= 1160px) {
  #timeline .tl-card .body {
    height: 315px;
  }
}

#timeline .tl-card .body p {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 18px;
}

#timeline .tl-card .body img {
  width: 100%;
  display: block;
}

#timeline .tl-card--step1 {
  background-color: #46b8e9;
}

#timeline .tl-card--step1 .head:after {
  border-color: #46b8e9;
}

#timeline .tl-card--step2 {
  background-color: red;
}

#timeline .tl-card--step2 .head:after {
  border-color: red;
}

#timeline .tl-card--step3 {
  background-color: #1eab95;
}

#timeline .tl-card--step3 .head:after {
  border-color: #1eab95;
}

#timeline .tl-card--step4 {
  background-color: #4d92eb;
}

#timeline .tl-card--step4 .head:after {
  border-color: #4d92eb;
}

#timeline .tl-card--step5 {
  background-color: #46b8e9;
}

#timeline .tl-card--step5 .head:after {
  border-color: #46b8e9;
}
/*# sourceMappingURL=index.a08e85d0.css.map */
